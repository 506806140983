import React from 'react';

const BreakpointContext = React.createContext();

const getBreakpoint = () => {
    if (typeof window === "undefined") return 'xs';
    const width = window.innerWidth;
    if (width < 576) return 'xs';
    if (width >= 576 && width < 768) return 'sm';
    if (width >= 768 && width < 992) return 'md';
    if (width >= 992 && width < 1260) return 'lg';
    if (width >= 1260 && width < 1600) return 'xl';
    return 'xxl';
};

const BreakpointProvider = ({children}) => {

    const [breakpoint, setBreakpoint] = React.useState(getBreakpoint());

    const updateBreakpoint = () => {
        setBreakpoint(getBreakpoint());
    };

    React.useLayoutEffect(() => {
        updateBreakpoint()
        window.addEventListener('resize', updateBreakpoint);
        return () => window.removeEventListener('resize', updateBreakpoint);
    }, []); // eslint-disable-line

    return (
        <BreakpointContext.Provider value={{breakpoint}}>
            {children}
        </BreakpointContext.Provider>
    );
};

const useBreakpoint = () => {
    const context = React.useContext(BreakpointContext);
    if (context === undefined) {
        throw new Error('useBreakpoint must be used within a BreakpointProvider');
    }
    return context;
};

export {BreakpointProvider, useBreakpoint};
