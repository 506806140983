import React from "react";
import {ConfigProvider} from 'antd-mobile';
import {RatesProvider} from "./src/context/ratesContext";
import {BreakpointProvider} from "./src/context/BreakpointContext";
import 'antd-mobile/bundle/style.css';
import './src/custom.css'

export const wrapRootElement = ({element}) => {
    return (
        <ConfigProvider>
            <BreakpointProvider>
                <RatesProvider>
                    {element}
                </RatesProvider>
            </BreakpointProvider>
        </ConfigProvider>
    )
};
